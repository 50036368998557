<h1 mat-dialog-title>{{ data.title }} <mat-icon mat-dialog-close>close</mat-icon></h1>
<div class="content" mat-dialog-content>
  <p>{{ data.primaryMessage }}</p>
  <br />
  <p>{{ data.secondaryMessage }}</p>

  <div class="delete-keywords">
    <mat-checkbox class="delete-checkbox" (change)="selected($event)"></mat-checkbox>
    <p>Also delete all the keywords inside the list?</p>
  </div>
</div>

<div class="actions" mat-dialog-actions>
  <kwfSecondaryButton [text]="'cancel'" [showArrow]="false" mat-dialog-close></kwfSecondaryButton>
  <kwfRedButton [text]="data.action" [mat-dialog-close]="true"></kwfRedButton>
</div>
