/* eslint-disable @typescript-eslint/no-misused-promises */
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IconService } from './services/icon.service';
import { SharedService } from './services/shared.service';
import { AuthService } from './services/auth.service';
import { SubscriptionService } from './services/subscription.service';
import { distinctUntilChanged, lastValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubscribeModalComponent } from './common/elements/modals/subscribe/subscribe-modal.component';
import { TermsAndConditionsModalComponent } from './common/elements/modals/terms-and-conditions/terms-and-conditions-modal.component';
import { ProfileService } from './services/profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewChecked {
  title = 'kwf';
  sideBarMode = true;

  constructor(
    public router: Router,
    private readonly iconService: IconService,
    readonly sharedService: SharedService,
    private readonly cdr: ChangeDetectorRef,
    readonly authService: AuthService,
    readonly subscriptionService: SubscriptionService,
    readonly profileService: ProfileService,
    public dialog: MatDialog
  ) {
    this.iconService.registerIcons();
  }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.authService.$authChanged.pipe(distinctUntilChanged()).subscribe(async (email) => {
      if (email) {
        const nextSubscriptionCheck: string | null =
          window.localStorage.getItem('nextSubscriptionCheck');
        if (!nextSubscriptionCheck || Date.now() > Number(nextSubscriptionCheck)) {
          const subs = await lastValueFrom(
            this.subscriptionService.getUserSubscription(email)
          ).catch(() => {});
          const user: any = await this.profileService.getUser();
          if (!subs?.active) {
            const dialogRef = this.dialog.open(SubscribeModalComponent, {
              height: '500px',
              width: '500px',
            });
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            dialogRef.afterClosed().subscribe(async (response) => {
              if (!response) {
                await this.authService.SignOut();
                return;
              }
              let url: string = response.monthlyUrl ?? '';
              if (response.selectedPlan === 'annually') {
                url = response.yearlyUrl ?? '';
              }
              window.localStorage.setItem('nextSubscriptionCheck', String(Date.now() + 1000 * 120));

              window.open(`${url}?prefilled_email=${email ?? ''}`, '_self');
            });
          } else {
            if (!user.tac) {
              const dialogRef = this.dialog.open(TermsAndConditionsModalComponent, {
                height: '220px',
                width: '500px',
              });
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              dialogRef.afterClosed().subscribe(async (response) => {
                if (!response) {
                  await this.authService.SignOut();
                  return;
                }
              });
            }
          }
        }
      }
    });
  }

  ngAfterViewChecked(): void {
    this.sharedService.$sideBarDisplay.subscribe((res) => {
      this.sideBarMode = res;
      this.cdr.detectChanges();
    });
  }
}
