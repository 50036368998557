<h1 mat-dialog-title>{{ data.title }} <mat-icon (click)="dismiss()">close</mat-icon></h1>
<div class="content" mat-dialog-content>
  <p>{{ data.primaryMessage }}</p>
  <br />
  <p>{{ data.secondaryMessage }}</p>
</div>

<div class="actions" mat-dialog-actions>
  <kwfRedButton [text]="'OK'" (action)="dismiss('OK')"></kwfRedButton>
  <kwfBlueButton [text]="'ABORT'" (action)="dismiss()"></kwfBlueButton>
</div>
