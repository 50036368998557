<h1 mat-dialog-title>Terms and Conditions <mat-icon mat-dialog-close>close</mat-icon></h1>
<div class="content" mat-dialog-content>
  <p>
    To use the platform, please read and accept our
    <a href="https://apexcomplianceprogram.com/terms-and-conditions/" target="_blank">
      Terms and Conditions
    </a>
  </p>

  <br />

  <div *ngIf="showSpinner" class="spinner-content">
    <app-spinner></app-spinner>
  </div>
</div>
<div class="actions" mat-dialog-actions>
  <kwfSecondaryButton [text]="'Refuse'" [showArrow]="false" mat-dialog-close></kwfSecondaryButton>
  <kwfBlueButton
    [text]="'ACCEPT'"
    (action)="accept()"
    [disabled]="showSpinner"
    [ngStyle]="{ opacity: showSpinner ? 0.4 : 1 }"
  ></kwfBlueButton>
</div>
