
<div class="tooltip-box" cdkScrollable>
  <button class="tooltip-container" mat-raised-button #tooltip="matTooltip"
          matTooltip="{{ tooltipText }}"
          [matTooltipPosition]="'before'"
          matTooltipHideDelay="0"
          matTooltipClass="tooltip"
          aria-label="Button that displays a tooltip that hides when scrolled out of the container">
    <mat-icon>question_mark</mat-icon>
  </button>
</div>
