<h1 mat-dialog-title>{{ data.title }} <mat-icon (click)="goToDashboard()">close</mat-icon></h1>
<div class="content" mat-dialog-content>
  <p>{{ data.primaryMessage }}</p>
  <br />
  <p>{{ data.secondaryMessage }}</p>
</div>

<div class="actions" mat-dialog-actions>
  <kwfBlueButton [text]="'GO BACK TO DASHBOARD'" (action)="goToDashboard()"></kwfBlueButton>
</div>
