import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-slide-toggle-btn',
  templateUrl: './slide-toggle-btn.component.html',
  styleUrls: ['./slide-toggle-btn.component.scss']
})
export class SlideToggleBtnComponent {
  @Input() label: string = '';
  @Input() checked: boolean = false;
  @Input() disabled:boolean = false;
  @Output() toggle: EventEmitter<any> = new EventEmitter<any>();

  onToggle(checked: boolean): void {
    this.toggle.emit(checked);
  }
}
