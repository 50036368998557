/* eslint-disable */
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { AddKeywordsToListComponent } from '../add-keywords-to-list/add-keywords-to-list.component';

@Component({
  selector: 'app-add-keyword-list',
  templateUrl: './add-keyword-list.component.html',
  styleUrls: ['./add-keyword-list.component.scss'],
})
export class AddKeywordListComponent {
  keywordList: FormControl<string | null> = new FormControl(null, Validators.required);
  constructor(
    public dialogRef: MatDialogRef<AddKeywordsToListComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      primaryMessage: string;
      secondaryMessage: string;
      action: string;
    }
  ) {}

  close(): void {
    if (this.validForm()) {
      return this.dialogRef.close(this.keywordList.value);
    }
    return this.dialogRef.close();
  }

  validForm(): boolean {
    return !!this.keywordList.value && this.keywordList.value !== '';
  }
}
