/* eslint-disable */
import {environment} from '../environments/environment';

export class Endpoints {
  static getUserID(): string {
    const user = JSON.parse(<string>localStorage.getItem('user'));
    return user && user.uid;
  }

  /*AUTH ENDPOINTS*/
  static forgotPassword: string = `${environment.baseUrl}/auth/forgot-password`;
  static changePassword: string = `${environment.baseUrl}/auth/change-password`;
  static createUser: string = `${environment.baseUrl}/users`;

  static getUser(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}`;
  }

  static getAllUsers(): string {
    return `${environment.baseUrl}/users`;
  }

  /*KEYWORD ENDPOINTS*/
  static allKeywords(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/keywords`;
  }
  /*KEYWORD ENDPOINTS*/
  static keywordsSuggestByAI(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/keywords/suggest/byAI`;
  }



  /*KEYWORD LIST ENDPOINTS*/
  static keywordList(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/keyword-lists`;
  }

  static keywordListById(id: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/keyword-lists/${id}`;
  }

  static smartSuggestion(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/keywords/suggest/byAI`;
  }

  static importKeywordList(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/keywords-lists/import`;
  }

  /* SCAN ENDPOINTS */
  static scanProjects(searchName?: string, page = 0): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects?page=${page}${searchName != null ? '&search=' + searchName : ''}`;
  }

  static scanOCRProject(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/ocr`
  }

  static scanMedia(projectId?: string): string {
    return `${environment.baseUrl}/media/scan-media${projectId != null ? '/' + projectId : ''}`;
  }

  static validateExceptions() {
    return `${environment.baseUrl}/exceptions/validate`;
  }

  static replace(type: 'docx' | 'xlsx'): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/replace/${type}`;
  }

  static scanReportsProjects(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}/reports`;
  }

  static websiteReport(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}/website/reports`;
  }

  static websiteKeywordDetail(scanProjectId: string, keywordId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${scanProjectId}/website/reports/keyword/${keywordId}`;
  }

  static generateFileReport(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}/website/generateFileReport`;
  }

  static generateOCRFileReport(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}/ocr/generateFileReport`;
  }

  static scanOCRTranscript(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}/ocr/transcripts`;
  }

  static ocrReport(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}/ocr/reports`;
  }

  static scanTranscript(projectId: string): string {
    return `${
      environment.baseUrl
    }/users/${this.getUserID()}/scan-projects/${projectId}/reports/transcripts`;
  }


  static scanWebsiteReportTranscript(projectId: string): string {
    return `${
      environment.baseUrl
    }/users/${this.getUserID()}/scan-projects/${projectId}/website/transcripts`;
  }

  static editOrDeleteReport(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}`;
  }

  static cancelScan(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}/cancel`;
  }

  /* SUBSCRIPTION ENDPOINTS */
  static getPrices(): string {
    return `${environment.baseUrl}/subscription/prices`;
  }

  static getUserSubscription(email: string): string {
    return `${environment.baseUrl}/subscription/${email}`;
  }

  static validateCheckout(checkoutId: string): string {
    return `${environment.baseUrl}/subscription/checkout/${checkoutId}`;
  }

  static snapshotKeywords(snapshotKeywordId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/snapshot-keywords/${snapshotKeywordId}/suggestions`;
  }

  static deleteUser(id: string): string {
    return `${environment.baseUrl}/users/usersByAdmin/${id}`;
  }

  static editUser(id: string): string {
    return `${environment.baseUrl}/users/usersByAdmin/${id}`;
  }

  static changeUserPassword (id: string): string {
    return `${environment.baseUrl}/users/change-password/${id}`;
  }

  /* FLAGGED WEBSITES */
  static flaggedWebsites(): string {
    return `${environment.baseUrl}/website-flags`;
  }

  /* INVOICES ENDPOINTS*/
  static invoices(): string {
    return `${environment.baseUrl}/invoices`;
  }

  static getInvoice(userId: string): string {
    return `${environment.baseUrl}/invoices/${userId}`;
  }
}
