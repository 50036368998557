import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {lastValueFrom, Observable} from 'rxjs';
import {
  DocxChanges,
  GetOCRProject,
  IScanMedia,
  KeywordsFound2,
  ScanProject,
  ScanProjectPdfOcrRequestBody,
  TranscriptDocumentDocxSection,
  TranscriptDocumentPDFSection,
  TranscriptSection,
  TranscriptSectionOCR
} from "../common/interfaces/scan/scan.interface";
import {Endpoints} from '../endpoints';
import {keywordTemporaryTransformer} from "../helpers/keyword-temporary-transformer";

@Injectable({
  providedIn: 'root',
})
export class ScanProjectService {
  constructor(private readonly http: HttpClient) {
  }

  scanProject(body: ScanProject): Observable<ScanProject> {
    return this.http.post<ScanProject>(Endpoints.scanProjects(), body);
  }

  // TODO: Define response type
  scanOCRProject(body: ScanProjectPdfOcrRequestBody): Observable<any> {
    return this.http.post<ScanProject>(Endpoints.scanOCRProject(), body);
  }

  getScanProject(id: string, videoUrl?: string | null): Promise<any> {
    return lastValueFrom(this.http.get<any>(Endpoints.scanReportsProjects(id) + (videoUrl != null ? `?videoUrl=${videoUrl}` : ''))).then(r => {
      r.keywordsFound = r.keywordsFound.map((k: any) => {
        if ((k as KeywordsFound2).keyword != null) {
          return {...k, ...(k as KeywordsFound2).keyword};
        } else {
          return k;
        }
      });
      // //TODO: remove this when database does not contains invalid variances and exceptions anymore
      r.keywordsFound = r.keywordsFound.map((k: any) => {
        return keywordTemporaryTransformer(k);
      });
      return r;
    });
  }

  generateReport(scanProjectId: string): Promise<any> {
    return lastValueFrom(this.http.get<any>(Endpoints.generateFileReport(scanProjectId))).then(r => {
      return r;
    });
  }

  generateOCRReport(scanProjectId: string): Promise<any> {
    return lastValueFrom(this.http.get<any>(Endpoints.generateOCRFileReport(scanProjectId))).then(r => {
      return r;
    });
  }

  getWebsiteReport(id: string): Promise<any> {
    return lastValueFrom(this.http.get<any>(Endpoints.websiteReport(id))).then(r => {
      r.keywordsFound = r.keywordsFound.map((k: any) => {
        if ((k as KeywordsFound2).keyword != null) {
          return {...k, ...(k as KeywordsFound2).keyword};
        } else {
          return k;
        }
      });
      // //TODO: remove this when database does not contains invalid variances and exceptions anymore
      r.keywordsFound = r.keywordsFound.map((k: any) => {
        return keywordTemporaryTransformer(k);
      });
      return r;
    });
  }

  getWebsiteKeywordDetail(scanProjectId: string, keywordId: string): Promise<{
    keywordDetail: { keyword_id: string, keyword_suggestions: string[], keyword_notes: string, scanProjectId: string; },
    pages: string[]
  }> {
    return lastValueFrom(this.http.get<any>(Endpoints.websiteKeywordDetail(scanProjectId, keywordId)));
  }

  getOcrReport(id: string): Promise<GetOCRProject> {
    return lastValueFrom(this.http.get<any>(Endpoints.ocrReport(id))).then(r => {
      r.keywordsFound = r.keywordsFound.map((k: any) => {
        if ((k as KeywordsFound2).keyword != null) {
          return {...k, ...(k as KeywordsFound2).keyword};
        } else {
          return k;
        }
      });
      r.keywordsFound = r.keywordsFound.map((k: any) => {
        return keywordTemporaryTransformer(k);
      });
      return r;
    })
  }

  getScanMediaById(projectId: string): Observable<IScanMedia> {
    return this.http.get<IScanMedia>(Endpoints.scanMedia(projectId));
  }

  getAllScanMedia(): Observable<IScanMedia[]> {
    return this.http.get<IScanMedia[]>(Endpoints.scanMedia());
  }

  postScanMedia(body: IScanMedia): Observable<{ projectId: string }> {
    return this.http.post<IScanMedia>(Endpoints.scanMedia(), body);
  }

  updateScanMedia(body: IScanMedia): Observable<any> {
    return this.http.put<IScanMedia>(Endpoints.scanMedia(), body);
  }

  getVideoScanProjectTranscript(id: string, section: number): Promise<TranscriptSection[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('section', section);
    return lastValueFrom(
      this.http.get<TranscriptSection[]>(Endpoints.scanTranscript(id), {params: queryParams})
    );
  }

  getWebScanReportProjectTranscript(id: string, page: string): Promise<TranscriptSection[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('page', page);
    return lastValueFrom(
      this.http.get<TranscriptSection[]>(Endpoints.scanWebsiteReportTranscript(id), {
        params: queryParams,
      })
    );
  }

  getPDFScanProjectTranscript(id: string, page: number): Promise<TranscriptDocumentPDFSection[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('page', page - 1);
    return lastValueFrom(
      this.http.get<TranscriptDocumentPDFSection[]>(Endpoints.scanTranscript(id), {
        params: queryParams,
      })
    );
  }

  getDocxOrXlsxScanProjectTranscript(id: string): Promise<TranscriptDocumentDocxSection[]> {
    return lastValueFrom(
      this.http.get<TranscriptDocumentDocxSection[]>(Endpoints.scanTranscript(id))
    );
  }

  getOCRScanProjectTranscript(id: string, file: string): Promise<TranscriptSectionOCR> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('file', file);
    return lastValueFrom(
      this.http.get<TranscriptSectionOCR>(Endpoints.scanOCRTranscript(id), {params: queryParams})
    );
  }

  postDocChanges(body: DocxChanges, type: 'docx' | 'xlsx'): Promise<any> {
    return lastValueFrom(this.http.post<any>(Endpoints.replace(type), body));
  }

  editProjectName(projectId: string, projectName: string): Promise<any> {
    const name = {name: projectName};
    return lastValueFrom(this.http.patch<any>(Endpoints.editOrDeleteReport(projectId), {name}));
  }

  deleteProject(projectId: string): Promise<any> {
    return lastValueFrom(this.http.delete<any>(Endpoints.editOrDeleteReport(projectId)));
  }

  cancelScan(projectId: string): Promise<any> {
    return lastValueFrom(this.http.delete(Endpoints.cancelScan(projectId)));
  }
}
