<div class="side-bar-container">
  <div class="logo-and-links">
    <a class="logo" routerLink="dashboard">
      <img src="../../../../assets/img/logo.svg" alt="Logo" />
    </a>
    <div class="links">
      <a *ngIf="user" routerLink="/dashboard" (click)="onClick('Dashboard')" routerLinkActive="active-link" mat-ripple>
        <mat-icon svgIcon="dashboard"></mat-icon>
        Dashboard
      </a>
      <a *ngIf="user" routerLink="/keywords/all-keywords" (click)="onClick('All Keywords')" routerLinkActive="active-link" mat-ripple>
        <mat-icon svgIcon="key"></mat-icon>
        All Keywords
      </a>
      <a *ngIf="user" routerLink="/keywords/keywords-list" (click)="onClick('Keywords List')" routerLinkActive="active-link" mat-ripple>
        <mat-icon svgIcon="list"></mat-icon>
        Keywords List
      </a>
      <a *ngIf="user && user.isAdmin" routerLink="/users/users" routerLinkActive="active-link" mat-ripple>
        <mat-icon svgIcon="patient_list"></mat-icon>
        Users
      </a>
      <a *ngIf="user && user.isAdmin" routerLink="/flagged-websites" routerLinkActive="active-link" mat-ripple>
        <mat-icon>error_outline</mat-icon>
        Flagged Websites
      </a>
    </div>
  </div>
  <div class="footer">
    <div class="profile">
      <div class="user">
        <mat-icon>person</mat-icon
        ><span *ngIf="!showSpinner">{{ user ? user.username : "---" }}</span
        ><app-spinner *ngIf="showSpinner || !user"></app-spinner>
      </div>
      <a routerLink="/profile" (click)="onClick('Manage Account')" *ngIf="!router.url.includes('/profile')"
        >manage account <mat-icon>arrow_forward</mat-icon></a
      >
    </div>
    <div class="logout" (click)="onLogout('Logout'); logout()"><mat-icon fontIcon="logout"></mat-icon> log out</div>
    <div class="help"  (click)="onClick('Help'); openHelp()" mat-ripple><span>?</span> help</div>
  </div>
</div>
