/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { Endpoints } from '../endpoints';
import { CreateUserInterface } from '../common/interfaces/user/create-user.interface';
import { ProfileService } from './profile.service';

interface forgotPassword {
  token?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any;

  $authChanged: BehaviorSubject<string | null | undefined> = new BehaviorSubject<
    string | null | undefined
  >(null);

  constructor(
    public http: HttpClient,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    private profileService: ProfileService
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.setUserToLocalStorage(user);
      } else {
        localStorage.setItem('user', 'null');
        JSON.parse(localStorage.getItem('user')!);
      }
    });
  }

  setUserToLocalStorage(user: any): void {
    localStorage.clear();
    this.$authChanged.next(user.email);
    this.userData = user;
    localStorage.setItem('user', JSON.stringify(this.userData));
    JSON.parse(localStorage.getItem('user')!);
  }

  async createUser(user: CreateUserInterface): Promise<CreateUserInterface> {
    const response = await lastValueFrom(this.http.post<CreateUserInterface>(Endpoints.createUser, user));
    return response;
  }

  SignIn(email: string, password: string): Promise<void> {
    return this.afAuth.signInWithEmailAndPassword(email, password).then((result) => {
      this.afAuth.authState.subscribe(async (user) => {
        this.setUserToLocalStorage(user);
        if (user) {
          await this.isAdmin()? void this.router.navigate(['/users/users']): void this.router.navigate(['/dashboard'])
        }
      });
    });
  }
  SignInWithToken(token: string): Promise<void> {
    return this.afAuth.signInWithCustomToken(token).then((result) => {
      this.afAuth.authState.subscribe((user) => {
        this.setUserToLocalStorage(user);
        if (user) {
          void this.router.navigate(['/dashboard']);
        }
      });
    });
  }

  forgotPassword(email: string): Promise<forgotPassword> {
    return lastValueFrom(
      this.http.post(`${Endpoints.forgotPassword}`, {
        email,
        returnToken: true,
      })
    );
  }

  changePassword(password: string, token: string): Promise<object> {
    const headers = new HttpHeaders({ 'x-kwf-reset-password-token': token });
    return lastValueFrom(
      this.http.post(
        `${Endpoints.changePassword}`,
        {
          password,
        },
        {
          headers,
        }
      )
    );
  }

  SignOut(error = undefined): Promise<void> {
    this.$authChanged.next(null);
    window.localStorage.removeItem('nextSubscriptionCheck');
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      void this.router.navigate(['/login', {error}]);
    });
  }

  isAdmin(): Promise<boolean> {
    return this.profileService.getUser()
      .then((user: any) => {
        return user.isAdmin;
      })
      .catch(error => {
        return false;
      });
  }
  
}
