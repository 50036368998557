import { Component, HostBinding, Input } from "@angular/core";

export enum SpinnerSize {
  SMALL, REGULAR
}

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {

  @Input() size = SpinnerSize.REGULAR;
  public SpinnerSize = SpinnerSize;
}
