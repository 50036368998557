<div class="privacy-section">
  <app-back-homepage-header></app-back-homepage-header>

  <div class="privacy-container">
    <div class="privacy-title-container">
      <h1>Privacy Policy</h1>
    </div>

    <small>Date of last update: April 1, 2023</small>
    <div class="privacy-description-container">
      <br />
      <p>
        This privacy policy (policy) describes how the Regulatory Education Events LLC dba
        Supplement Advisory Group brands (we, our or Regulatory Education Events LLC dba Supplement
        Advisory Group) collect, share, use and otherwise process personal information in connection
        with our various websites (public sites), products and services including any consulting,
        SaaS platforms and other applications we provide to our customers (products) or matters
        which otherwise reference this policy. Accordingly, this policy should be considered notice
        to you and any individuals whose personal information we process.
      </p>
      <br />

      <p>
        We recognize and respect the privacy of our customers and users of our products, as well as
        to visitors to our public sites. Regulatory Education Events LLC dba Supplement Advisory
        Group has a tradition of upholding high ethical standards in its business practices,
        including compliance with laws and regulations applicable to the protection of personal
        information.
      </p>
      <br />

      <p>
        This updated policy is effective as of the date of last update and applies to our use of
        your personal information as of that date.
      </p>
      <br />

      <h2>1. DEFINITIONS.</h2>

      <p>For purposes of this policy, the following definitions shall apply:</p>
      <br />

      <p>
        “<strong>Regulatory Education Events LLC dba Supplement Advisory Group</strong>” means Apex
        Compliance (Regulatory Education Events LLC dba Supplement Advisory Group) and its
        subsidiaries and affiliates, including its and their respective successors, divisions, and
        brands.
      </p>
      <br />

      <p>
        “<strong>Employer</strong>” means an entity that is authorized to use products from
        Regulatory Education Events LLC dba Supplement Advisory Group through a contract with a
        customer, and employs individuals who are required to submit to Regulatory Education Events
        LLC dba Supplement Advisory Group certain personal information regarding themselves and/or
        others for processing under the terms of the policies of their employer and/or applicable
        law.
      </p>
      <br />

      <p>
        “<strong>Personal information</strong>” means any information or set of information which
        identifies, relates to, describes, is reasonably capable of being associated with or could
        be used by or on behalf of Regulatory Education Events LLC dba Supplement Advisory Group to
        identify an individual. Personal information does not include information that is encoded or
        anonymized or maintained in a manner that is not reasonably capable of being associated with
        an individual.
      </p>
      <br />

      <h2>2. INFORMATION WE COLLECT</h2>
      <p>
        In general, you can visit our public sites without telling us who you are or directly
        providing us any information about yourself. We may collect and use certain information from
        our visitors for our general business use, for example, to help us administer our business,
        improve our services or adjust our site to meet our users’ needs and expectations.
      </p>
      <br />

      <p>The types of personal information we collect include:</p>
      <br />

      <ul>
        <li>
          <strong>Contact information -</strong> For example, your name, mailing address, email
          address and telephone number.
        </li>

        <li>
          <strong>Information you share -</strong> Any information you choose to share with us
          through our public site and products.
        </li>

        <li>
          <strong>Employment information -</strong> If you apply for a job through one of our public
          sites, we collect the personal information you include in your application including your
          name, mailing address, email address, phone number and any other personal information you
          provide regarding your employment history.
        </li>

        <li>
          <strong>Any information your employer requires you to share -</strong> This may include
          HR-related information, financial information and other personal information you or your
          employer directly or indirectly submit to Regulatory Education Events LLC dba Supplement
          Advisory Group for processing through one of our products.
        </li>

        <li>
          <strong>Information regarding your device.</strong> This may include information on the
          type of device you use to access our public site and/or products, including a device
          identifier, IP address or operating system.
        </li>

        <li>
          <strong>Information regarding your location.</strong> This may include information from
          your device including your exact or an approximate location when you access our public
          site or products.
        </li>

        <li>
          <strong>Log and analytics data.</strong> This may include information about the browser
          you are using to view our public sites and online products, the site you came from or
          visited when you leave. If you are using one of our mobile app products, we may also be
          able to see where you downloaded the app and the frequency with which you use it.
        </li>
      </ul>
      <br />

      <h2>3. HOW WE COLLECT INFORMATION</h2>

      <h3>a. Information collected automatically</h3>
      <p>
        We may gather usage information from any visitor to our public site or products. Our servers
        may track and collect information about your browser, domain name, IP address and the web
        pages you visit. This information generally does not identify you. It is aggregated to
        measure the number of visits, your use of the site, pages viewed and so on. This information
        lets us see how visitors are finding our public site and also tells us which pages and
        screens of our public sites and products are visited the most often so we can make our
        products and information more useful to everyone. We may share experiential information with
        third parties to help us develop and improve our public sites and products.
      </p>
      <br />

      <h3>
        b. Information provided to Regulatory Education Events LLC dba Supplement Advisory Group
      </h3>
      <p>
        In addition to the information automatically collected by your browser, we may collect
        personal information through our public sites and/or products.
      </p>
      <br />

      <ul>
        <li>
          <strong>Public site:</strong> There may be areas on our public sites where you may provide
          personal information to obtain access to certain products including online services not
          available to anonymous visitors.
        </li>

        <li>
          <strong>Products:</strong> Our products offer services to employers to assist with their
          compliance, legal and governance obligations. Access to our products is restricted to
          customers within which we have contracts and their affiliates, either or both of which are
          employers. Employers contract with Regulatory Education Events LLC dba Supplement Advisory
          Group for many reasons including, in some cases, to assist in their collection of certain
          personal information necessary for their compliance with applicable laws and regulations.
          The employer determines what information is collected, how it is updated and how long it
          is held.
        </li>
      </ul>

      <p>
        If you or your employer use our products you will be required to provide identifying
        information (e.g., a username) and select a password, which will be used to access our
        products, including any personal information stored therein. During the registration process
        we collect information (registration information), which may include your name, email and
        brokerage account number(s) and associated holdings. This information may be submitted by
        you, your employer, your brokers or other parties. Some of this information may be optional
        depending on your employer’s compliance requirements. Regulatory Education Events LLC dba
        Supplement Advisory Group is not responsible for the accuracy of any information provided to
        us.
      </p>
      <br />
      <p>
        Your employer may directly or indirectly share your personal information under certain
        circumstances including:
      </p>
      <br />

      <ul>
        <li>
          Your employer is required to comply with a legal or governance obligation and has sent us
          your information in connection with compliance effort. Your consent was likely given as a
          condition of employment. To verify the consent process please contact your employer’s
          Human Resources department. If you believe your consent was not obtained, Regulatory
          Education Events LLC dba Supplement Advisory Group may remove your data upon written
          notice from you after we consult with your employer.
        </li>

        <li>
          Regulatory Education Events LLC dba Supplement Advisory Group also may have received your
          personal information if a member of your household is employed by an employer that is
          required to comply with a legal or regulatory obligation and has directly or indirectly
          provided your personal information to Regulatory Education Events LLC dba Supplement
          Advisory Group to us in connection with their compliance efforts. To verify the consent
          process, please contact the employer’s Human Resources team or similar department. If you
          believe your consent was not obtained, you may advise Regulatory Education Events LLC dba
          Supplement Advisory Group to remove your personal information from our records and we will
          consult with the employer and customer regarding any such requests.
        </li>

        <li>
          <strong>Email contact:</strong> If your email address is submitted through the public
          sites or if you send us an email or request information, Regulatory Education Events LLC
          dba Supplement Advisory Group will retain your contact information and use it to respond
          to your inquiry. We may also use your email and contact information for our general
          business purposes, including to notify you about product updates and technical
          notifications (e.g., firewall or IP address changes). You may unsubscribe or change your
          email address for these communications at any time by following the instructions in each
          such email. If your email address is in our application, Regulatory Education Events LLC
          dba Supplement Advisory Group may use your email address to respond to your inquiries or
          communicate with you regarding the application. By providing personal information through
          our public sites, you explicitly agree to our collection and use of all such information
          as described in this policy. You agree we may use the personal information you provide to
          respond to your inquiries or contact you regarding customer service or otherwise related
          to our public sites and products.
        </li>
      </ul>
      <br />

      <h3>c. Cookies</h3>
      <p>
        Our public sites and online products use cookies and other similar technologies to help
        personalize your online experience and to remember you when you return to the site. A cookie
        is a text file which is anonymously placed on your hard disk by a webpage server. Cookies
        cannot be used to run programs or deliver viruses to your computer. Cookies or other
        technologies placed when you visit our site do not personally identify you, but they do
        allow us to monitor certain statistics, which help us improve our services, such as how many
        site visitors view certain pages on our website. Some cookies are deleted once you leave our
        sites (session cookies), while other cookies remain after your leave our public sites and
        products (persistent cookies) so you are recognized when you return to that public site or
        product, as applicable.
      </p>
      <br />

      <p>
        You can accept or decline most cookies. Some cookies are essential for use of our public
        sites or products. Others may improve performance and functionality, allow for the
        collection of analytics and customization, advertising, social networking and more. How you
        manage cookies may be up to you or your IT department. Many web browsers automatically
        accept cookies, but you can typically modify your browser setting to decline cookies if you
        choose.
      </p>
      <br />

      <p>
        If you choose to decline certain cookies, you may not be able to fully experience the
        interactive features of our websites or services, such as being automatically recognized as
        a registered user.
      </p>
      <br />

      <p>
        You can also manage your cookie preferences through our public sites. Please click here to
        review the terms of our cookie policy, including how to manage cookie preferences on our
        sites. Please be aware deleting cookies may affect your use of or access to certain public
        sites and products in the future.
      </p>
      <br />

      <h3>4. CHILD PRIVACY</h3>
      <p>
        Our public sites and products are intended for adults. We do not knowingly attempt to
        collect or solicit any information from anyone under the age of 16 through our products or
        public sites. In the event we become aware we have inadvertently collected information from
        someone under the age of 16, we will delete that information as quickly as possible. If you
        believe we may have collected personal information from someone under the age of 16, please
        contact us at
        <a href="mailto:support@apexcomplianceprogram.com">support@apexcomplianceprogram.com</a>
      </p>
      <br />

      <h3>5. HOW WE USE YOUR INFORMATION</h3>
      <p>
        Our use of personal information is subject to the policy in effect at the time of use. The
        provisions of this policy supersede all previous notices or statements regarding our privacy
        practices with respect to this public site and our products.
      </p>
      <br />

      <p>
        We use the personal information we collect to help us better maintain and develop our public
        sites and products to meet the needs of our visitors and customer users. We sometimes use
        aggregated data (independent of any personal identifiers) for research and commercial
        purposes. This information may include the areas of our public sites and products are
        accessed most often.
      </p>
      <br />

      <p>
        We use the personal Information we collect through our products only to perform the services
        for which we are contractually engaged. Each employer submitting personal information to us
        is responsible for ensuring the information is accurate, complete and current. We may make
        automated decisions on compliance issues based upon our customer’s instructions.
      </p>
      <br />

      <br />
      <h2>6. HOW WE SHARE YOUR INFORMATION</h2>

      <p>We may share your personal information as:</p>
      <br />

      <p>
        Internally - We may share your personal information among the Regulatory Education Events
        LLC dba Supplement Advisory Group group of companies and brands whether to help manage
        public sites, delivery of our products, development of new products or marketing and
        promotion of our offerings, which we believe may be of interest to you.
      </p>
      <br />

      <p>
        With our customers/employers and their respective affiliates in connection with the delivery
        of products to them.
      </p>
      <br />

      <p>
        With our service providers and business partners - We may share your personal information
        with third parties who help manage our public sites and delivery of our products and
        development of new products, as well as advertising and promotion of our offerings which we
        believe may be of interest to you.
      </p>
      <br />

      <p>
        To Regulatory Education Events LLC dba Supplement Advisory Group with laws or protect
        ourselves - This may include responding to a court order or subpoena, or in connection with
        a request from a governmental or quasi-governmental agency or regulatory agency or
        investigative body or to enforce our terms and conditions of use, acceptable use policies
        and the like.
      </p>
      <br />

      <p>
        In connection with a merger, acquisition or other reorganization but only to the extent it
        is reasonable and necessary to do so in relation to such transaction and any related
        diligence, transfer of assets or other sale of Regulatory Education Events LLC dba
        Supplement Advisory Group or a portion thereof.
      </p>
      <br />

      <p>For other reasons we may describe to you - This may include, but is not limited, to:</p>
      <br />

      <p>
        The personal information provided to us in connection with our products is used only to
        identify you as part of the products we provide to our customers. It is possible we may be
        asked by an employer to provide or an employer may provide certain brokers a list of your
        accounts as part of the products we provide.
      </p>

      <br />
      <h2>7. INTERNATIONAL VISITORS AND USERS</h2>

      <p>
        If you are visiting our public site from outside the United States, you expressly and
        affirmatively consent to our processing of your personal information in the United States.
        You acknowledge and agree the level of data protection in the United States may not be equal
        to the level of protection under the laws of other countries.
      </p>
      <br />

      <p>
        When you and your employer use our products, you and your employer are responsible for
        compliance with all data security laws and regulations applicable to Regulatory Education
        Events LLC dba Supplement Advisory Group’s collection of personal information whether
        directly or indirectly through your employer, a third party (e.g., your brokerage firm) or
        you. This includes, without limitation, your employer’s receipt of any required consent and
        providing a mechanism for you to access, update, correct or delete personal information from
        the products, as described above.
      </p>
      <br />

      <p>
        You understand and agree personal information submitted through our public sites and
        products is transferred to Regulatory Education Events LLC dba Supplement Advisory Group in
        the United States, where it may be used to respond to your requests or deliver products to
        our customers. If you do not want Regulatory Education Events LLC dba Supplement Advisory
        Group to use any personal information about you which has been collected through our
        delivery of products to customers, please contact your employer. Failure to grant consent to
        your employer or a decision to withdraw consent from them could impact Regulatory Education
        Events LLC dba Supplement Advisory Group’s ability to provide products.
      </p>

      <br />

      <h2>8. HOW TO ACCESS OR UPDATE YOUR INFORMATION</h2>

      <p>
        Individuals whose personal information is processed by Regulatory Education Events LLC dba
        Supplement Advisory Group have the right to access, correct or delete their personal
        information. We have different methods of addressing changes depending on how we collected
        information:
      </p>
      <br />

      <p>
        If personal information about you has been provided to Regulatory Education Events LLC dba
        Supplement Advisory Group through the delivery of our products to customers, please contact
        your employer. Employers are responsible for providing you a mechanism to update, correct,
        delete or make changes to your personal information. We will work closely with your employer
        to ensure such information is accessible for such purposes.
      </p>
      <br />

      <p>
        If you provided any personal information through our public site(s) you may contact us at
        <a href="mailto:support@apexcomplianceprogram.com"
          >mailto:support@apexcomplianceprogram.com</a
        >.
      </p>

      <br />
      <h2>9. OUR SECURITY MEASURES</h2>
      <p>
        While we cannot guarantee the security of personal information, Regulatory Education Events
        LLC dba Supplement Advisory Group does employ certain administrative, physical and technical
        security measures to protect against the loss, misuse and alteration of the information
        under our control.
      </p>
      <br />

      <p>
        Please be aware any information you send by email is not encrypted and is susceptible to
        interception over the internet.
      </p>
      <br />

      <p>
        While we take steps to protect your personal information, you also play a role in protecting
        your information, including by not sharing your log-in or password with anyone. Regulatory
        Education Events LLC dba Supplement Advisory Group will never ask you for this information.
      </p>
      <br />

      <p>
        If we receive instructions using your log-in and password, we will assume that the
        instructions have been authorized by you.
      </p>

      <br />
      <h2>10. LINKS FROM OUR PUBLIC SITES OR PRODUCTS TO OTHERS</h2>
      <p>
        Regulatory Education Events LLC dba Supplement Advisory Group’s public sites and products
        may contain links to third-party websites, plug-ins, applications and other online services.
        When you access these external resources, the owners of those resources may collect certain
        information about you subject to their own separate policies. Access to those resources is
        not subject to this policy. Regulatory Education Events LLC dba Supplement Advisory Group is
        not responsible for the privacy practices or the content of any resources we do not own or
        directly control, and we encourage you to carefully read the applicable policies and terms
        and conditions applicable to such resources.
      </p>

      <br />
      <h2>11. CHANGES TO THIS PRIVACY POLICY</h2>

      <p>
        We may amend this policy from time to time. The use of your personal information is subject
        to policy and terms of use in effect at the time of use. The provisions contained in this
        privacy policy supersede all previous notices or policies regarding our privacy practices
        with respect to the public sites and products. Please check this page periodically for
        updates.
      </p>

      <br />
      <h2>12. DO NOT TRACK REQUESTS</h2>

      <p>
        There is no industry standard approach to sending, processing, and addressing Do Not Track
        requests from browsers, and please note that our Site does not respond to these Do Not Track
        requests at this time. More information on “Do Not Track” requests is available here:
        <a href="https://allaboutdnt.com/">https://allaboutdnt.com/</a>
      </p>

      <br />
      <h2>13. CONTACTING Regulatory Education Events LLC dba Supplement Advisory Group</h2>

      <p>
        If you have any questions, comments, access requests or any other issues arising under
        regarding our privacy policy or our website, you can contact us at
        <a href="mailto:support@apexcomplianceprogram.com">support@apexcomplianceprogram.com</a>
      </p>
    </div>
  </div>
</div>
