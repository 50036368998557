import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'scanning-loader',
  templateUrl: './scanning-loader.component.html',
  styleUrls: ['./scanning-loader.component.scss'],
})
export class ScanningLoaderComponent implements OnInit, OnDestroy {
  @Input() type!: 'video' | 'website' | 'document' | 'audio';
  @Input() pageProgress!: number;
  @Input() uploadProgress!: number;
  @Input() pages: number = 0;
  @Output() cancel = new EventEmitter();

  warningMessage!: string;
  bodyContent: any;
  scanContent: any;

  constructor(readonly sharedService: SharedService) {}

  ngOnInit(): void {
    this.sharedService.closeSideBar();
    this.bodyContent = document.getElementsByClassName('drawer-container');
    this.checkScanType();
  }

  checkScanType(): void {
    switch (this.type) {
      case 'video':
        this.scanContent = document.getElementsByClassName('media-scan-container');
        this.warningMessage = 'Larger videos may take a while to scan.';
        break;
      case 'audio':
        this.scanContent = document.getElementsByClassName('media-scan-container');
        this.warningMessage = 'Larger audio files may take a while to scan.';
        break;
      case 'website':
        this.scanContent = document.getElementsByClassName('website-scan-container');
        this.warningMessage = 'Larger websites may take a while to scan.';
        break;
      case 'document':
        // FIXME: add new style to class document-scan-container
        this.scanContent = document.getElementsByClassName('media-scan-container');
        this.warningMessage = 'Larger documents may take a while to scan.';
        break;
    }
    this.bodyContent[1].style.overflow = 'hidden';
    this.bodyContent[1].style.maxHeight = '100vh';
    this.scanContent[0].style.overflow = 'hidden';
    this.scanContent[0].style.maxHeight = '100vh';
  }

  ngOnDestroy(): void {
    this.sharedService.openSideBar();
    if (this.bodyContent[1]) {
      this.bodyContent[1].style.overflow = 'auto';
      this.bodyContent[1].style.maxHeight = 'auto';
    }
    if ((this.type === 'video' || 'document' || 'website' || 'audio') && this.scanContent[0]) {
      this.scanContent[0].style.overflow = 'auto';
      this.scanContent[0].style.maxHeight = 'auto';
    }
  }

  cancelScan(): void {
    this.cancel.emit();
  }
}
