<div class="subscription-header">
  <div class="header-logo-container">
    <a routerLink="/" class="header-logo"><img src="assets/img/logo.svg" alt="app-logo" /></a>
  </div>
  <kwfWhiteButton
    class="back-button"
    [text]="'GO BACK TO THE HOMEPAGE'"
    [color]="'blue'"
    (action)="router.navigate(['/'])"
  ></kwfWhiteButton>
</div>
