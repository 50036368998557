export const environment = {
  environmentName: 'development',
  production: false,
  baseUrl: 'https://api-dev.apexcomplianceprogram.com/api/v1',
  // baseUrl: 'http://localhost:3000/api/v1',
  stripeCustomerPortalUrl: 'https://billing.stripe.com/p/login/test_5kAbIR8LT8lzelidQQ',
  firebase: {
    apiKey: 'AIzaSyAthESTR8a-5uCPsThBLgHlgho2Eeo8o_A',
    authDomain: 'keyword-finder-platform.firebaseapp.com',
    projectId: 'keyword-finder-platform',
    storageBucket: 'keyword-finder-platform.appspot.com',
    messagingSenderId: '77510476034',
    appId: '1:77510476034:web:847578566553d9c417c21b',
    measurementId: 'G-PBQWDX2E2J',
  },
  ocrBucket: 'gs://apex-ocr-dev'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
