<div class="privacy-section">
  <app-back-homepage-header></app-back-homepage-header>

  <div class="privacy-container">
    <div class="privacy-title-container">
      <h1>Cookie policy</h1>
    </div>

    <small>Date of last update: April 1, 2023</small>

    <div class="privacy-description-container">
      <br />

      <h2>COOKIES</h2>
      <p>
        Our sites use cookies and other similar technologies to help personalize your online
        experience and to remember you when you return to the site. A cookie is a text file which is
        anonymously placed on your hard disk by a webpage server. Cookies cannot be used to run
        programs or deliver viruses to your computer. Cookies or other technologies placed when you
        visit our site do not personally identify you, but they do allow us to monitor certain
        statistics, which help us improve our services, such as how many site visitors view certain
        pages on our website. Some cookies are deleted once you leave our sites (session cookies),
        while other cookies remain after your leave our sites (persistent cookies) so you are
        recognized when you return to the site.
      </p>
      <br />

      <p>
        You also have the ability to accept or decline cookies. Most web browsers automatically
        accept cookies, but you can usually modify your browser setting to decline cookies if you
        prefer. If you choose to decline cookies, you may not be able to fully experience the
        interactive features of our websites or services, such as being automatically recognized as
        a registered user.
      </p>
      <br />

      <p>
        Additionally, if you would prefer to delete cookies, you can find instructions for deleting
        cookies for all major browsers on this link:
        <a href="https://www.lifewire.com/how-to-delete-cookies-2617981"
          >https://www.lifewire.com/how-to-delete-cookies-2617981</a
        >. Please be aware, however, doing this may affect your ability to use some applications in
        the future.
      </p>

      <h2>MANAGE COOKIE PREFERENCES</h2>
      <h3>Child privacy</h3>
      <p>
        Because of the nature of our business, our products and services are not designed to appeal
        to minors, and therefore we do not knowingly attempt to solicit or receive any information
        from children under 16. If we are aware information is being provided by a person under the
        age of 16 we will not collect the information.
      </p>
      <br />

      <h3>How we use your information</h3>
      <p>
        The use of your information is subject to the privacy policy in effect at the time of use.
        The provisions of this privacy policy supersede all previous notices or statements regarding
        our privacy practices with respect to this site. We use the information we automatically
        collect to help us better plan our sites to meet your needs. We sometimes use aggregated
        data (independent of any personal identifiers) for research and commercial purposes. This
        information includes what areas of our public site and what services are accessed most
        often. We use the information you or an employer provides to us through our products only to
        perform the services for which we are engaged. Each employer submitting information to us is
        responsible for ensuring the information is accurate, complete and current; we will take
        reasonable steps to ensure the information we collect and use is relevant to its intended
        use. We may make automated decisions on compliance issues based upon the employer’s
        instructions.
      </p>
      <br />

      <h3>Disclosing information to third parties</h3>
      <p>
        Regulatory Education Events LLC dba Supplement Advisory Group does not disclose or share any
        personal information with third parties, except employers who use our products. The
        information you provide to us or is provided to us by an employer is used only to identify
        you as part of the services we provide to an employer. It is possible we may be asked by an
        employer to provide a list of accounts as part of the services we provide. The only
        circumstance under which your personal information may be disclosed is (1) if you request or
        authorize it, (2) if the information is provided to comply with the law, applicable
        regulations, governmental and quasi-governmental requests, court orders or subpoenas, to
        enforce our terms of use or other agreements, or to protect our rights, property or safety
        or the rights, property or safety of others, (3) if the disclosure is done as part of a
        purchase, transfer or sale of services or assets, or (4) if the information is provided to
        an employer.
      </p>
      <br />

      <p>
        We also may be required to disclose an individual’s personal information in response to a
        lawful request by public authorities, including to meet national security or law enforcement
        requirements.
      </p>
      <br />

      <h3>International site visitors</h3>
      <p>
        If you are visiting our public site from outside the United States, you expressly and
        affirmatively consent to transferring your personal information to Regulatory Education
        Events LLC dba Supplement Advisory Group in the United States, where it will be maintained
        and processed on our servers. You acknowledge and agree the level of data protection in the
        United States may not be equal to the level of protection under the laws of other countries.
      </p>
      <br />

      <p>
        When your employer uses our products, the employer is responsible for compliance with all
        data security laws and regulations applicable to the employer’s submission of that Personal
        Information to Regulatory Education Events LLC dba Supplement Advisory Group. This includes,
        without limitation, obtaining any required consent and providing a mechanism for you to
        access, update, correct or delete that personal information as described above.
      </p>
      <br />

      <p>
        The personal information submitted through the public site is transferred to Regulatory
        Education Events LLC dba Supplement Advisory Group in the United States will be used to
        respond to your requests for information.
      </p>
      <br />

      <p>
        The personal information submitted through our products is transferred to Regulatory
        Education Events LLC dba Supplement Advisory Group in the United States will be used to
        support the functions necessary to deliver the services to your employer.
      </p>
      <br />

      <p>
        If you do not want Regulatory Education Events LLC dba Supplement Advisory Group to use your
        personal data any longer, please contact your employer’s Compliance department. Please note
        failure to grant consent or a decision to withdraw consent could impact Regulatory Education
        Events LLC dba Supplement Advisory Group’s ability to provide products or services.
      </p>
      <br />

      <h3>How to access or update your information</h3>
      <p>
        Individuals whose personal data is processed by Regulatory Education Events LLC dba
        Supplement Advisory Group have the right to access, correct or delete their personal data.
        Employers are responsible for providing a mechanism for the individuals whose information
        they submit to Regulatory Education Events LLC dba Supplement Advisory Group to update,
        correct, delete or make changes their personal information. If your information was
        submitted by an employer, please contact that employer’s Compliance department, who will be
        responsible for making the change to your information. If you provided contact information
        through our public site you may contact us at
        <a href="mailto:support@apexcomplianceprogram.com">support@apexcomplianceprogram.com</a>
      </p>
      <br />

      <h3>Our security measures</h3>
      <p>
        Regulatory Education Events LLC dba Supplement Advisory Group has administrative, physical
        and technical security measures in place to protect against the loss, misuse and alteration
        of the information under our control. Please be aware any information you send by email is
        not encrypted and is susceptible to interception over the internet. While we take steps to
        protect your personal information, you also play a role in protecting your information. You
        can help to maintain the security of your online transactions by not sharing your log-on
        information or password with anyone. If we receive instructions using your log-on
        information and password we will assume that the instructions have been authorized by you.
      </p>
      <br />

      <h3>Links from our site to others</h3>
      <p>
        Regulatory Education Events LLC dba Supplement Advisory Group’s public site may contain
        links to other sites such as those of our affiliates and third parties. When you access
        these external websites, the providers of the sites will have access to certain information
        about you. Regulatory Education Events LLC dba Supplement Advisory Group is not responsible
        for the privacy practices or the content of any websites we do not directly control, and we
        encourage you to read the applicable privacy policies and terms and conditions of such
        parties or web sites.
      </p>
      <br />

      <h3>Changes to this privacy policy</h3>
      <p>
        We will occasionally amend this privacy policy to reflect company and customer feedback and
        we reserve the right to make changes to this privacy policy at any time. The use of your
        information is subject to the privacy policy and terms of use in effect at the time of use.
        The provisions contained in this privacy policy supersede all previous notices or policies
        regarding our privacy practices with respect to this site.
      </p>
      <br />

      <h3>Contacting Regulatory Education Events LLC dba Supplement Advisory Group</h3>
      <p>
        If you have any questions, comments, access requests or any other issues arising under
        regarding our privacy policy or our website, you can contact our Compliance Department at
      </p>

      <a href="mailto:support@apexcomplianceprogram.com">support@apexcomplianceprogram.com</a>
      <br />
    </div>
  </div>
</div>
