import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ProfileService } from '../../../services/profile.service';
import {AnalyticsService} from "../../../services/analytics.service";

@Component({
  selector: 'side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  user: any;
  showSpinner: boolean = false;

  constructor(
    public router: Router,
    private readonly authService: AuthService,
    private readonly profileService: ProfileService,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.getUserInfo();
  }

  async logout(): Promise<void> {
    await this.authService.SignOut();
  }

  getUserInfo(): void {
    this.showSpinner = true;
    this.profileService
      .getUser()
      .then((response) => {
        this.user = response;
        this.showSpinner = false;
      })
      .catch((err) => {
        console.error(err);
        this.showSpinner = false;
      });
  }

  async onClick(button: string): Promise<void> {
    await this.analyticsService.trackEvent(`Dashboard button clicked: ${button}`, { button: button })
  }

  onLogout(button: string): void {
    this.analyticsService.logout();
  }

  async openHelp(): Promise<void> {
    window.open('https://apexcomplianceprogram.com/help', '_blank');
  }
}
