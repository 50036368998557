<h1>{{ type != 'ocr' ? ('Scanning ' + type) : 'Uploading files' }}</h1>
<img src="../../../../assets/img/scan-loader.gif" alt="Scan" />
<ng-container *ngIf="type ==='ocr'">
    <div class="progress-ocr-container">
        <div class="progress-ocr" *ngFor="let file of filesToUpload  | slice:0:5">{{ file.filePath.split('/').pop() }} -- Progress: {{file.progress| number:'1.0-0'}}%</div>
        <div class="progress-ocr" *ngFor="let file of filesToUpload  | slice:5:10">{{ file.filePath.split('/').pop() }} -- Progress: {{file.progress| number:'1.0-0'}}%</div>
    </div>
</ng-container>
<div class="progress" *ngIf="uploadProgress && type != 'ocr' || uploadProgress === 0">Progress: {{uploadProgress | number:'1.0-0'}}%</div>
<div class="progress" *ngIf="false">{{ pages }} pages scanned...</div>
<div class="larger-doc-warning">{{warningMessage}}</div>
<div class="cancel" (click)="cancelScan()">cancel {{ type === 'ocr' ? 'upload' : 'scan' }}</div>
