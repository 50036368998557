<div class="privacy-section">
  <app-back-homepage-header></app-back-homepage-header>

  <div class="privacy-container">
    <div class="privacy-title-container">
      <h1>Terms and Conditions</h1>
    </div>

    <small>Date of last update: April 1, 2023</small>
    <div class="privacy-description-container">
      <br />
      <p>
        The following Terms and Conditions of Use (“Terms”) apply to all visitors of
        <a href="https://apexcomplianceprogram.com/">https://apexcomplianceprogram.com/</a>
        and any Website provided on or in connection therewith (collectively, “the Website”). The
        Website is operated by Regulatory Education Events LLC dba Supplement Advisory Group,
        (“Regulatory Education Events LLC dba Supplement Advisory Group”, “We”, “Us”, or “Our”).
        This is a legally binding agreement between Us and You, the visitor to the Website.
      </p>
      <br />

      <p>
        By accessing and using the Website, you acknowledge that you have read, understood and agree
        to be bound by these Terms without limitation or qualification, including our
        <a routerLink="/privacy-policy">Privacy Policy</a>
        (which is expressly incorporated herein by reference) and the data collection and use of
        your information set forth therein. We reserve the right to make changes in rules and
        regulations from time to time. Your continued use of the Website means that you accept and
        agree to the changes posted and are bound by any such revisions. Visit this page
        periodically to review the terms and conditions. We reserve the right to terminate or
        suspend your account and/or access to the Website without any notice and in our sole
        discretion and reserve the rights to pursue any other remedies available to Us by law.
      </p>
      <br />

      <p>
        <strong>
          THESE TERMS CONTAIN A MANDATORY INDIVIDUAL ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER
          PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES,
          RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMITS THE REMEDIES AVAILABLE TO YOU IN
          THE EVENT OF A DISPUTE. PLEASE READ THIS DOCUMENT CAREFULLY.
        </strong>
      </p>
      <br />

      <h2>Your Account</h2>
      <p>
        You may be required to register for an account with us in order to access or use parts of
        the Website. Your account gives you access to the Website and functionality that we may
        establish and maintain from time to time and in our sole discretion. If you elect to take
        advantage of such features, you must register through the Website by completing the
        applicable registration form to create your account with a unique username and password. The
        decision to provide this information is purely optional; however, if you elect not to
        provide such information, you may not be able to access certain content or participate in
        certain features of the Website.
      </p>
      <br />

      <p>
        If you choose to register an account on the Website, you will create a username and password
        for your account. By creating an account, you agree: (a) that you are at least twenty-one
        years old; (b) that you are able to and have the appropriate authority and authorization to
        create a binding agreement with Regulatory Education Events LLC dba Supplement Advisory
        Group; (c) to provide true, accurate, current and complete information about yourself when
        we request it; (d) to maintain and promptly update this information to keep it true,
        accurate, current and complete to the extent the Website permit such updates; and (e) to use
        limited-access portions of the Website only using access credentials that we have issued to
        you and not to violate these Terms. It is your responsibility to maintain the
        confidentiality of your account, including your login credentials and information associated
        with the account. Regulatory Education Events LLC dba Supplement Advisory Group encourages
        you to use “strong” passwords (passwords that use a combination of upper and lower case
        letters, numbers and symbols) with your account. You must notify Regulatory Education Events
        LLC dba Supplement Advisory Group immediately of any unauthorized use of your credentials or
        any other breach of security. Even if you notify us, you will be responsible for any
        activities that occur using your access credentials, including any charges resulting from
        the use of your account. Regulatory Education Events LLC dba Supplement Advisory Group will
        not be liable for any losses or damages caused by any unauthorized use of your account,
        financial or otherwise.
      </p>
      <br />

      <p>
        By providing Regulatory Education Events LLC dba Supplement Advisory Group your email
        address you consent to receiving Website-related notices, including any notices required by
        law, in lieu of communication by postal mail. We may also use your email address to send you
        other messages, such as changes to features of the Website and special offers, promotions,
        or relevant news about Regulatory Education Events LLC dba Supplement Advisory Group. If you
        do not want to receive such email messages, you may opt-out or change your preferences on
        the personal settings page associated with your account or by following the instructions
        included in the email you receive. Opting out may prevent you from receiving email messages
        regarding updates, improvements, or offers.
      </p>
      <br />

      <p>
        When you sign up for or use our products and Website, you may give us permission to access
        your information in other Website. For example, you may link your Facebook account, which
        allows us to obtain information from those accounts (like your full name and email). The
        information we get from those Website often depends on your settings or their privacy
        policies, so be sure to check what those are.
      </p>
      <br />

      <p>
        If you violate these Terms, we may terminate your account without prior notice. We may also
        terminate your account without prior notice, if we in our sole discretion decide that it
        would be in Regulatory Education Events LLC dba Supplement Advisory Group’s best interests
        to do so.
      </p>
      <br />

      <h2>Limited License</h2>
      <p>
        The content, information, software, designs, materials, functions and data included in and
        contained on the Website or made available via the Website (the “Content”) is protected by
        intellectual property and other laws. You must comply with these and any other applicable
        laws when you access Services. All Content including but not limited to designs, structure,
        text, logos, product packaging, trade dress, product configuration, brand names, product
        names, graphics, arrangement of content, user and visual interfaces, artwork, sounds, music,
        programming, and all other copyrightable work or trademarks on this Website are protected by
        copyright and/or trademark law and are the property of Regulatory Education Events LLC dba
        Supplement Advisory Group or its licensors. Except as allowed under applicable law, no
        Content, in whole or in part, may be used, copied, sold, reproduced, duplicated, modified,
        or otherwise exploited without prior express written permission from Regulatory Education
        Events LLC dba Supplement Advisory Group.
      </p>
      <br />

      <p>
        As a condition of your use of the Website, you warrant to Regulatory Education Events LLC
        dba Supplement Advisory Group that you will not use the Website for any purpose that is
        unlawful or prohibited by these Terms or any applicable law. You may not use the Website in
        any manner which could damage, disable, overburden, or impair the Website or our computer
        network or interfere with any other party’s use and enjoyment of the Website. You may not
        convey unauthorized claims about the curative or health enhancing effects of our products or
        suggest that we have made such claims. You may not obtain or attempt to obtain any materials
        or information through any means not intentionally made available or provided for through
        the Website. You agree not to attempt to reverse-engineer the Website or access any
        protected or non-public portion of the Website without express authorization from Regulatory
        Education Events LLC dba Supplement Advisory Group.
      </p>
      <br />

      <p>
        In addition to our other legal rights, we may limit or terminate your license to use the
        Website, or certain features of the Website, at any time and for any reason, without any
        prior notice to you whatsoever.
      </p>
      <br />

      <h2>Submissions</h2>

      <p>
        The Website may allow you to submit, embed, display, transmit, or otherwise distribute
        audio, video, text, photos, or other materials (collectively, “User Submissions”) to or
        through the Website. When you provide User Submissions, you grant to Regulatory Education
        Events LLC dba Supplement Advisory Group a non-exclusive, royalty-free, fully paid,
        perpetual, worldwide, irrevocable and fully sublicensable and transferable (in whole or in
        part) license under all copyrights, trademarks, patents, trade secrets, privacy and
        publicity rights and other intellectual property rights you own or control to: (i) use,
        reproduce, transmit, modify, index, adapt, publish, translate, create derivative works from,
        distribute, display and otherwise exploit such content throughout the world in any media,
        whether now known or hereafter invented, including for any and all purposes, including
        commercial or marketing purposes, all without further notice to you, with or without
        attribution, and without the requirement of any permission from or payment to you or any
        other person or entity, and (ii) to use your name, persona or likeness alone or in
        connection with such uses, without any obligation or remuneration to you. Without
        limitation, the granted rights include the right to: (a) configure, host, index, cache,
        archive, store, digitize, compress, optimize, modify, reformat, edit, adapt, publish in
        searchable format, and remove such User Submissions and combine same with other materials,
        and (b) use any ideas, concepts, know-how, or techniques contained in any User Submissions
        for any purposes whatsoever, including developing, manufacturing, and marketing products
        and/or Website. Except as prohibited by law, you hereby waive, and you agree to waive, any
        moral rights (including attribution and integrity) that you may have in any User
        Submissions, even if it is altered or changed in a manner not agreeable to you. To the
        extent not waivable, you irrevocably agree not to exercise such rights (if any) in a manner
        that interferes with any exercise of the granted rights. You understand that you will not
        receive any fees, sums, consideration, or remuneration for any of the rights granted in this
        Section.
      </p>
      <br />

      <p>
        You agree that your User Submissions will be limited to messages and material that are
        proper and related to the Website. By posting, uploading, inputting, providing or submitting
        your User Submission you warrant and represent that you own or otherwise control all of the
        rights to your User Submission as described in this section including, without limitation,
        all the rights necessary for you to provide, post, upload, input or submit the Submissions
        and grant the necessary license noted in the preceding paragraph. You represent, warrant,
        and covenant that your User Submission will not:
      </p>
      <br />

      <ul>
        <li>
          <p>
            Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as
            rights of privacy and publicity) of others.
          </p>
        </li>

        <li>
          <p>
            Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory,
            infringing, obscene, indecent or unlawful topic, name, material or information.
          </p>
        </li>

        <li>
          <p>
            Upload files that contain software or other material protected by intellectual property
            laws (or by rights of privacy of publicity) unless you own or control the rights thereto
            or have received all necessary consents.
          </p>
        </li>

        <li>
          <p>
            Constitute or encourage violence or a criminal offense, violate the rights of any party,
            including intellectual property rights, or otherwise give rise to liability or violate
            any law;
          </p>
        </li>

        <li>
          <p>
            Impersonate any other person, user, or Regulatory Education Events LLC dba Supplement
            Advisory Group, or may be false, fraudulent, deceptive, inaccurate, or misleading, or
            that misrepresents your identity or affiliation with a person or Regulatory Education
            Events LLC dba Supplement Advisory Group;
          </p>
        </li>

        <li>
          <p>
            Include other peoples’ personal information, such as another person’s address, phone
            number, e-mail address, social security number, credit card number, medical information,
            financial information, or any other information that may be used to track, contact, or
            impersonate that individual;
          </p>
        </li>

        <li>
          <p>
            Upload files that contain viruses, corrupted files, or any other similar software or
            programs that may damage the operation of another’s computer.
          </p>
        </li>

        <li>
          <p>
            Advertise or offer to sell or buy any goods or Website for any business purpose, unless
            such user Submission specifically allows such messages.
          </p>
        </li>

        <li>
          <p>Conduct or forward surveys, contests, pyramid schemes or chain letters.</p>
        </li>

        <li>
          <p>
            Download any file posted by another User Submission that you know, or reasonably should
            know, cannot be legally distributed in such manner.
          </p>
        </li>

        <li>
          <p>
            Falsify or delete any author attributions, legal or other proper notices or proprietary
            designations or labels of the origin or source of software or other material contained
            in a file that is uploaded.
          </p>
        </li>

        <li>
          <p>
            Restrict or inhibit any other user from using and enjoying the Website and User
            Submissions.
          </p>
        </li>

        <li>
          <p>
            Violate any code of conduct or other guidelines which may be applicable for any
            particular User Submission.
          </p>
        </li>

        <li>
          <p>
            Harvest or otherwise collect information about others, including e-mail addresses,
            without their consent.
          </p>
        </li>

        <li>
          <p>Violate any applicable laws or regulations</p>
        </li>
      </ul>

      <br />
      <p>
        We may refuse or remove a User Submission without notice for any reason in our sole
        discretion, including our belief that a User Submission may violate these Terms and
        Conditions or be otherwise objectionable. However, we will have no obligation to review,
        monitor, display, post, store, maintain, accept or otherwise make use of, User Submissions,
        and you agree that neither we nor our employees or agents will be liable for User
        Submissions or any loss or damage to you and any other person or entity resulting from User
        Submissions. We cannot and do not assure that other users are or will be complying with the
        foregoing or any other agreement, and, as between you and us, you hereby assume all risk of
        harm, injury, or damages resulting from any such lack of compliance. Once you upload a User
        Submission, it may become public. We are not responsible for any harm, injury, or damages
        whatsoever, arising out of your upload of a User Submission that becomes public.
      </p>
      <br />

      <h2>Linking to This Website.</h2>
      <p>
        Running or displaying this Website or any information or material displayed on this Website
        in frames or through similar means on another site and creating any link from another site
        to any page on this Website without our prior written permission is strictly prohibited.
        Permitted links to this Website must comply with applicable laws, rules and regulations.
      </p>
      <br />

      <h2>Third- Party Sites.</h2>
      <p>
        The Website may contain links to third party web sites that are meant for convenience of the
        users and visitors (“Linked Sites”), and the inclusion of any link does not imply
        endorsement by Regulatory Education Events LLC dba Supplement Advisory Group of the site or
        any association with its operators. The Linked Sites are not under the control of Regulatory
        Education Events LLC dba Supplement Advisory Group and Regulatory Education Events LLC dba
        Supplement Advisory Group is not responsible for the content of any Linked Site, including
        without limitation any link contained in a Linked Site, or any changes or updates to a
        Linked Site. Regulatory Education Events LLC dba Supplement Advisory Group is not
        responsible for webcasting or any other form of transmission received from any Linked Site.
        Linking to any such third-party site is at your own risk and Regulatory Education Events LLC
        dba Supplement Advisory Group is not responsible for reliability or accuracy of information
        or data of such sites. Please review the Terms and Conditions and Privacy Policy of
        third-party sites that you visit.
      </p>
      <br />

      <h2>No Warranty/ Limitations of Liability</h2>

      <p>
        <strong>
          THE INFORMATION, CONTENT, SOFTWARE, PRODUCTS, AND WEBSITE INCLUDED IN OR AVAILABLE THROUGH
          THE WEBSITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY
          MADE TO THE INFORMATION HEREIN. GURUSOLVE AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS
          AND/OR CHANGES IN THE PRODUCTS OR WEBSITE OFFERED, OR TO THE WEBSITE AT ANY TIME. ADVICE
          RECEIVED VIA THE WEBSITE SHOULD NOT BE RELIED UPON FOR PERSONAL, MEDICAL, LEGAL OR
          FINANCIAL DECISIONS AND YOU SHOULD CONSULT AN APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE
          TAILORED TO YOUR SITUATION.
        </strong>
      </p>

      <br />

      <p>
        <strong>
          REGULATORY EDUCATION EVENTS LLC DBA SUPPLEMENT ADVISORY GROUP AND/OR ITS SUPPLIERS MAKE NO
          REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY
          OF THE INFORMATION, SOFTWARE, PRODUCTS, WEBSITE AND RELATED GRAPHICS CONTAINED ON THE
          WEBSITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH
          INFORMATION, SOFTWARE, PRODUCTS, WEBSITE AND RELATED GRAPHICS ARE PROVIDED “AS IS” WITHOUT
          WARRANTY OR CONDITION OF ANY KIND. REGULATORY EDUCATION EVENTS LLC DBA SUPPLEMENT ADVISORY
          GROUP AND/OR ITS SUPPLIERS OR SERVICE PROVIDERS HEREBY DISCLAIM ALL WARRANTIES AND
          CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, WEBSITE AND RELATED
          GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
        </strong>
      </p>

      <br />

      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT REGULATORY EDUCATION EVENTS
        LLC DBA SUPPLEMENT ADVISORY GROUP AND/OR ITS SUPPLIERS OR SERVICE PROVIDERS, OR ANY OF THEIR
        EMPLOYEES, AGENTS, DIRECTORS, MANAGERS, MEMBERS, SHAREHOLDERS, OR AFFILIATES BE LIABLE FOR
        ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
        WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR INTERRUPTION OF BUSINESS, SYSTEM
        FAILURES, INCLUDING VIRUSES OBTAINED DURING HYPERLINK, LOST PROFITS OR REVENUES, COSTS OF
        REPLACEMENT GOODS, DATA NON-DELIVERY, MISDELIVERY, CORRUPTION, DESTRUCTION OR OTHER
        MODIFICATION LOSS OR DAMAGE TO DATA ARISING OUT OF THE USE OF OR INABILITY TO USE THIS
        WEBSITE, ANY REGULATORY EDUCATION EVENTS LLC DBA SUPPLEMENT ADVISORY GROUP PRODUCT OR
        PRODUCT SOLD BY REGULATORY EDUCATION EVENTS LLC DBA SUPPLEMENT ADVISORY GROUP OR LINKS TO
        ANY THIRD PARTY SITE FROM THE WEBSITE, DAMAGES RESULTING FROM USE OF OR RELIANCE ON THE
        INFORMATION PRESENT, LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED
        WITH THE USE OR PERFORMANCE OF THE WEBSITE, WITH THE DELAY OR INABILITY TO USE THE WEBSITE,
        THE PROVISION OF OR FAILURE TO PROVIDE WEBSITE, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS,
        WEBSITE AND RELATED GRAPHICS OBTAINED THROUGH THE WEBSITE, OR OTHERWISE ARISING OUT OF THE
        USE OF THE WEBSITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR
        OTHERWISE, EVEN IF REGULATORY EDUCATION EVENTS LLC DBA SUPPLEMENT ADVISORY GROUP OR ANY OF
        ITS SUPPLIERS OR SERVICE PROVIDERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE
        SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
        CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE
        DISSATISFIED WITH ANY PORTION OF THE WEBSITE, OR WITH ANY OF THESE TERMS AND CONDITIONS,
        YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE WEBSITE.
      </p>
      <br />

      <h2>Indemnification</h2>
      <p>
        You agree to defend, indemnify and hold harmless Regulatory Education Events LLC dba
        Supplement Advisory Group and its affiliates, subsidiaries, agents, licensors, managers,
        members, and their employees, contractors, agents, officers and directors, from and against
        any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses
        (including but not limited to attorney’s fees) arising from: (i) your use of and access to
        the Website, including any data or content transmitted or received by you; (ii) your
        violation of any term of these Terms; (iii) your violation of any third-party right,
        including without limitation any right of privacy, right of publicity or Intellectual
        Property Rights; (iv) your violation of any applicable law, rule or regulation; (v) your
        User Submissions or any that is submitted via your account; or (vi) any other party’s access
        and use of the Website with your unique username, password, or other appropriate security
        code. You also agree to indemnify us for any loss, damages, or costs, including reasonable
        attorneys’ fees, resulting from your use of any data gathering and extraction tools or any
        other action you take that imposes an unreasonable burden or load on our infrastructure.
      </p>
      <br />

      <h2>BINDING ARBITRATION/CLASS WAIVER</h2>
      <p>
        For any dispute with Regulatory Education Events LLC dba Supplement Advisory Group, you
        agree to first contact us at and attempt to resolve the dispute with us informally. In the
        unlikely event that Regulatory Education Events LLC dba Supplement Advisory Group has not
        been able to resolve a dispute it has with you after attempting to do so for a period of
        sixty (60) days, we each agree to resolve any claim, dispute, or controversy (excluding any
        of Regulatory Education Events LLC dba Supplement Advisory Group’ claims for injunctive or
        other equitable relief) arising out of or in connection with or relating to these Terms and
        Conditions, or the breach or alleged breach thereof (collectively, “Claims”), by binding
        arbitration by the American Arbitration Association (“AAA”) in the city of Boulder, Colorado
        under the commercial rules then in effect for the AAA, except as provided herein. The award
        rendered by the arbitrator shall include costs of arbitration, reasonable attorneys’ fees
        and reasonable costs for expert and other witnesses, and any judgment on the award rendered
        by the arbitrator may be entered in any court of competent jurisdiction. Nothing in this
        Section shall be deemed as preventing Regulatory Education Events LLC dba Supplement
        Advisory Group from seeking injunctive or other equitable relief from the courts as
        necessary to protect any of Regulatory Education Events LLC dba Supplement Advisory Group’
        proprietary interests.
      </p>
      <br />

      <p>
        ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
        CLASS MEMBER IN ANY PURPORTED CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL
        ACTION OR OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER APPLIES TO CLASS ARBITRATION, AND,
        UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS.
        YOU AGREE THAT, BY ENTERING INTO THIS AGREEMENT, YOU AND REGULATORY EDUCATION EVENTS LLC DBA
        SUPPLEMENT ADVISORY GROUP ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN
        A CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE
        PROCEEDING OF ANY KIND.
      </p>
      <br />

      <h2>Choice of Law / Jurisdiction</h2>
      <p>
        The laws of the State of Colorado govern these Terms and Conditions and any dispute of any
        sort that may arise between you and Regulatory Education Events LLC dba Supplement Advisory
        Group or its affiliates, without regard to conflict of laws rules, as if entered into by
        residents of Texas and fully performed therein. You irrevocably consent to the jurisdiction
        of the state courts located in or serving Boulder County, Colorado or a federal court of
        competent jurisdiction in the District of Colorado for any action relating to the Services
        or these Terms. Notwithstanding any other provision of these Terms, we may seek injunctive
        or other equitable relief from any court of competent jurisdiction.
      </p>
      <br />

      <p>
        We make no representation that any Website is appropriate or available for use in any
        particular location. Those who choose to access the Website do so on their own initiative
        and are responsible for compliance with all applicable laws including any applicable local
        laws.
      </p>
      <br />

      <h2>Trademark / Copyright/ Notice of Infringement</h2>
      <p>
        All trademarks, service marks, and trade names are trademarks or registered trademarks and
        are proprietary to Regulatory Education Events LLC dba Supplement Advisory Group, or other
        respective owners that have granted Regulatory Education Events LLC dba Supplement Advisory
        Group license to use such marks.
      </p>
      <br />

      <p>
        Pursuant to Title 17, United States Code, Section 512(c)(2), notifications of claimed
        copyright infringement under United States copyright law should be sent to Service
        Provider’s Designated Agent. ALL INQUIRIES NOT RELEVANT TO THE FOLLOWING PROCEDURE WILL
        RECEIVE NO RESPONSE. For your complaint to be valid under the DMCA, you must provide the
        following information in writing:
      </p>
      <br />

      <ol>
        <li>
          An electronic or physical signature of a person authorized to act on behalf of the
          copyright owner;
        </li>

        <li>Identification of the copyrighted work that you claim has been infringed;</li>

        <li>
          Identification of the material that is claimed to be infringing and where it is located on
          the Website;
        </li>

        <li>
          Information reasonably sufficient to permit Regulatory Education Events LLC dba Supplement
          Advisory Group to contact you, such as your address, telephone number, and, e-mail
          address;
        </li>

        <li>
          A statement that you have a good faith belief that use of the material in the manner
          complained of is not authorized by the copyright owner, its agent, or law; and
        </li>

        <li>
          A statement, made under penalty of perjury, that the above information is accurate, and
          that you are the copyright owner or are authorized to act on behalf of the owner.
        </li>
      </ol>
      <br />

      <p>
        UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT ONLINE MATERIAL IS INFRINGING, YOU MAY
        BE SUBJECT TO CRIMINAL PROSECUTION FOR PERJURY AND CIVIL PENALTIES, INCLUDING MONETARY
        DAMAGES, COURT COSTS, AND ATTORNEYS’ FEES.
      </p>
      <br />

      <p>
        Please note that this procedure is exclusively for notifying Regulatory Education Events LLC
        dba Supplement Advisory Group and its affiliates that your copyrighted material has been
        infringed. The preceding requirements are intended to comply with Regulatory Education
        Events LLC dba Supplement Advisory Group’s rights and obligations under the DMCA, including
        17 U.S.C. §512(c), but do not constitute legal advice. It may be advisable to contact an
        attorney regarding your rights and obligations under the DMCA and other applicable laws.
      </p>
      <br />

      <h2>Accessibility</h2>
      <p>
        Regulatory Education Events LLC dba Supplement Advisory Group is committed to ensuring
        accessibility of the Website to people with disabilities under the Americans With
        Disabilities Act of 1990. Thus, it is our goal that the Website and content produced by our
        company will meet the legally appropriate standards so that anyone can access and use the
        Website. Website content provided by third-parties, including any user-generated content,
        may not meet said standards because the creation of that content is out of our control.
        However, we strive to ensure that this content is accessible and will request it be made
        accessible as necessary.
      </p>
      <br />

      <p>
        If you have an accessibility issue with the Website, please reach out to us by email to
        <a href="mailto:sales@CannaMeadows.com">sales@CannaMeadows.com</a> with our concern so that
        we can take the appropriate action.
      </p>
      <br />

      <h2>Assignment / Entire Agreement / Severability / Admissibility</h2>
      <p>
        These Terms and Conditions, and any rights and licenses granted hereunder, may not be
        transferred or assigned by you, but may be assigned by Regulatory Education Events LLC dba
        Supplement Advisory Group without restriction. Any attempted transfer or assignment in
        violation hereof shall be null and void.
      </p>
      <br />

      <p>
        Unless otherwise specified herein, these Terms and Conditions constitutes the entire
        agreement between the user and Regulatory Education Events LLC dba Supplement Advisory Group
        with respect to the Website and it supersedes all prior or contemporaneous communications
        and proposals, whether electronic, oral or written, between the user and Regulatory
        Education Events LLC dba Supplement Advisory Group with respect to the Website.
      </p>
      <br />

      <p>
        If any part of these Terms is determined to be invalid or unenforceable pursuant to
        applicable law including, but not limited to, the warranty disclaimers and liability
        limitations set forth above, then the invalid or unenforceable provision will be deemed
        superseded by a valid, enforceable provision that most closely matches the intent of the
        original provision and the remainder of the agreement shall continue in effect.
      </p>
      <br />

      <p>
        A printed version of this agreement and of any notice given in electronic form shall be
        admissible in judicial or administrative proceedings based upon or relating to this
        agreement to the same extent and subject to the same conditions as other business documents
        and records originally generated and maintained in printed form. It is the express wish to
        the parties that this agreement and all related documents be drawn up in English.
      </p>
      <br />

      <h2>Modification and Changes to these Terms and Conditions</h2>
      <p>
        We reserve the right, in our sole discretion, to change these Terms and Conditions at any
        time by posting a revised Terms and Conditions on the Website. It is your responsibility to
        check periodically for any changes we may make to these Terms and Conditions. Your continued
        use of the Website following the posting of changes to these Terms and Conditions other
        policies means you accept the changes.
      </p>
      <br />

      <h2>No Waiver</h2>
      <p>
        No waiver of any term of these Terms and Conditions shall be deemed a further or continuing
        waiver of such term or any other term, and Regulatory Education Events LLC dba Supplement
        Advisory Group’s failure to assert any right or provision under these Terms and Conditions
        shall not constitute a waiver of such right or provision.
      </p>
      <br />

      <h2>Contact Us</h2>
      <p>
        If you have any questions about these Terms and Conditions, this Website, or your dealings
        with Us, please contact Us at:
      </p>
      <br />
      <p>
        Regulatory Education Events LLC dba Supplement Advisory Group by
        <a href="mailto:support@apexcomplianceprogram.com">support@apexcomplianceprogram.com</a>
      </p>
    </div>
  </div>
</div>
