<h1 mat-dialog-title>{{ data.title }} <mat-icon mat-dialog-close>close</mat-icon></h1>
<div class="content" mat-dialog-content>
  <p>{{ data.primaryMessage }}</p>
  <br />
  <p>{{ data.secondaryMessage }}</p>

  <app-search-bar
    (action)="getInputValue($event)"
    [searchBarLabel]="'Search for keyword'"
  ></app-search-bar>

  <div class="all-keyword-list">
    <p *ngFor="let list of filteredData">
      <mat-checkbox class="dynamic-table-checkbox" (change)="getSelectedList(list)"></mat-checkbox>
      {{ list.name }}
    </p>
  </div>
</div>
<div class="actions" mat-dialog-actions>
  <kwfSecondaryButton [text]="'cancel'" [showArrow]="false" mat-dialog-close></kwfSecondaryButton>
  <kwfBlueButton [text]="data.action" [mat-dialog-close]="true"></kwfBlueButton>
</div>
