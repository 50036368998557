import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Endpoints } from '../endpoints';

export interface PricesAPIResponse {
  monthly: number;
  yearly: number;
  discount?: number | null;
  monthlyUrl: string;
  yearlyUrl: string;
}

export interface SubscriptionAPIResponse {
  planId: string;
  active: boolean;
  subscriptionId: string;
}

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(public http: HttpClient) {}

  getPrices(): Observable<PricesAPIResponse> {
    return this.http.get<PricesAPIResponse>(Endpoints.getPrices());
  }
  getUserSubscription(email: string): Observable<SubscriptionAPIResponse> {
    return this.http.get<SubscriptionAPIResponse>(Endpoints.getUserSubscription(email));
  }
  validateCheckout(checkoutId: string): Observable<any> {
    // FIXME: insert type instead of any
    return this.http.get<any>(Endpoints.validateCheckout(checkoutId));
  }
}
