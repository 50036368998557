import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  $sideBarDisplay: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  closeSideBar(): void {
    this.$sideBarDisplay.next(true);
  }

  openSideBar(): void {
    this.$sideBarDisplay.next(false);
  }

  setRiskRatingIcon(riskLevel: string): string {
    let riskIconName: string = '';
    switch (riskLevel?.toLowerCase()) {
      case 'highest':
        riskIconName = 'keyboard_double_arrow_up';
        break;
      case 'high':
        riskIconName = 'keyboard_arrow_up';
        break;
      case 'medium':
        riskIconName = 'drag_handle';
        break;
      case 'low':
        riskIconName = 'keyboard_arrow_down';
        break;
      case 'lowest':
        riskIconName = 'keyboard_double_arrow_down';
        break;
    }
    return riskIconName;
  }

  generateUUID(): string {
    let d = new Date().getTime(); // Timestamp
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  checkRiskOrder(keyword: any): number {
    let riskLevel: number = 1;
    switch (keyword?.riskRating?.toLowerCase()) {
      case 'highest':
        riskLevel = 5;
        break;
      case 'high':
        riskLevel = 4;
        break;
      case 'medium':
        riskLevel = 3;
        break;
      case 'low':
        riskLevel = 2;
        break;
      case 'lowest':
        riskLevel = 1;
        break;
    }
    return riskLevel;
  }
}
