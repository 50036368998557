import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kwfRedButton',
  templateUrl: './red-btn.component.html',
  styleUrls: ['./red-btn.component.scss', '../btn.component.scss'],
})
export class RedBtnComponent implements OnInit {
  @Input() text!: string;
  @Input() disabled = false;
  @Output() action = new EventEmitter();

  ngOnInit(): void {}
}
