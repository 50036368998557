<div class="toast-content">
  <div class="icon-messages">
    <mat-icon class="success-icon" svgIcon="check-circle"></mat-icon>
    <div class="texts">
      <strong>
        {{ data.title }}
      </strong>
      <small>
        {{ data.message }}
      </small>
    </div>
  </div>

  <mat-icon class="close-icon" fontIcon="close" (click)="_snackRef.dismiss()"></mat-icon>
</div>
