import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SubscriptionService } from '../../../../services/subscription.service';
import { lastValueFrom } from 'rxjs';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-terms-and-conditions-modal',
  templateUrl: './terms-and-conditions-modal.component.html',
  styleUrls: ['./terms-and-conditions-modal.component.scss'],
})
export class TermsAndConditionsModalComponent implements OnInit {
  showSpinner: boolean = false;
  constructor(
    readonly subscriptionService: SubscriptionService,
    public dialogRef: MatDialogRef<TermsAndConditionsModalComponent>,
    readonly profileService: ProfileService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      primaryMessage: string;
      secondaryMessage: string;
      action: string;
    }
  ) {}

  async ngOnInit(): Promise<void> {
    this.showSpinner = true;
    this.showSpinner = false;
  }

  accept(): void {

    this.profileService.acceptTac()

    this.dialogRef.close({
      response: true
    });
  }
}
