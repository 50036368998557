/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { ChartConfiguration } from 'chart.js';
import { RiskRating } from 'src/app/common/interfaces/scan/scan.interface';

@Component({
  selector: 'doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent implements OnInit {
  @Input() data?: RiskRating;
  public doughnutChartLabels: string[] = [];
  public doughnutChartLabelsValue: string[] = [];
  public doughnutChartDatasets: ChartConfiguration<'doughnut'>['data']['datasets'] = [
    {
      data: [],
      backgroundColor: [
        '#DC1919',
        '#F86800',
        '#F5CF08',
        'rgba(57, 150, 0, 0.6)',
        '#399600',
        'rgba(0, 0, 0, 0.3)',
      ],
      label: 'Series A',
    },
  ];
  public doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
    cutout: 72,
    responsive: false,
    borderColor: 'rgba(0, 0, 0, 0.0)',
    plugins: {
      legend: {
        position: 'right',
        onClick: function (event, legendItem) {},
        labels: {
          font: {
            family: 'Raleway, sans-serif',
            style: 'normal',
            size: 16,
            weight: '400',
          },
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 17,
        },
      },
    },
  };
  @Output() chartLabelValue = new EventEmitter<any>();

  constructor(readonly sharedService: SharedService) {}

  ngOnInit(): void {
    if (this.data) {
      const total = Object.values(this.data).reduce((acc, curr) => acc + curr, 0);
      Object.entries(this.data).forEach(([key, value]) => {
        if (total > 0) {
          this.doughnutChartLabels.push(key.toString().charAt(0).toUpperCase() + key.slice(1));
          this.doughnutChartLabelsValue.push(String(Math.round((value / total) * 100)) + ' ' + '%');
        } else {
          this.doughnutChartLabelsValue.push('0' + ' %');
          this.doughnutChartLabels.push(key.toString().charAt(0).toUpperCase() + key.slice(1));
        }
        this.doughnutChartDatasets[0].data.push(value);
      });
      this.chartLabelValue.emit(this.doughnutChartLabelsValue);
      if (!this.doughnutChartDatasets[0].data[5] && total <= 0)
        this.doughnutChartDatasets[0].data.push(100);
    }
  }
}
