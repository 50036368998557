<div class="selection-container">
  <span
    >{{ item }} selected: <strong>{{ length }}</strong></span
  >
  <div class="actions">
    <kwfWhiteButton
      class="first-btn"
      *ngIf="secondaryButton"
      [color]="'red'"
      [text]="secondaryButton"
      (action)="secondaryAction.emit()"
    ></kwfWhiteButton>
    <kwfBlueButton
      *ngIf="primaryButton"
      [text]="primaryButton"
      (action)="primaryAction.emit()"
    ></kwfBlueButton>
  </div>
</div>
