import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../common/shared.module';
import { DataPolicyComponent } from './data-policy/data-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { UserGuidelinesComponent } from './user-guidelines/user-guidelines.component';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [
    DataPolicyComponent,
    TermsOfServiceComponent,
    CookiePolicyComponent,
    UserGuidelinesComponent,
  ],
  imports: [CommonModule, SharedModule, RouterLink],
})
export class PrivacyTermsPagesModule {}
