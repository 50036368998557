import { keywordOptionsSanitizer } from "./keyword-options-sanitizer";
import {KeywordsFound} from "../common/interfaces/scan/scan.interface";

export function keywordTemporaryTransformer(k:any) {
  if(k.variances){
    const sanitizedVariances = keywordOptionsSanitizer(k.variances);
    k.variances = sanitizedVariances;
  }
  if(k.exceptions){
    const sanitizedExceptions = keywordOptionsSanitizer(k.exceptions);
    k.exceptions = sanitizedExceptions;
  }
  if(k.suggestions){
    const sanitizedSuggestions = keywordOptionsSanitizer(k.suggestions);
    k.suggestions = sanitizedSuggestions;
  }
  return k;
}
