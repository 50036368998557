import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scan-error',
  templateUrl: './scan-error.component.html',
  styleUrls: ['./scan-error.component.scss'],
})
export class ScanErrorComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      primaryMessage: string;
      secondaryMessage: string;
      action: string;
    },
    public dialogRef: MatDialogRef<ScanErrorComponent>,
    readonly router: Router
  ) {}

  goToDashboard(): void {
    this.dialogRef.close();
    void this.router.navigate(['/dashboard']);
  }
}
