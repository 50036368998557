import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SubscriptionService } from '../../../../services/subscription.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-subscribe-modal',
  templateUrl: './subscribe-modal.component.html',
  styleUrls: ['./subscribe-modal.component.scss'],
})
export class SubscribeModalComponent implements OnInit {
  selectedPlan: string = 'annually';
  monthlyPrice?: number;
  monthlyUrl?: string;
  yearlyPrice?: number;
  yearlyUrl?: string;
  discount?: number | null;
  showSpinner: boolean = false;
  constructor(
    readonly subscriptionService: SubscriptionService,
    public dialogRef: MatDialogRef<SubscribeModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      primaryMessage: string;
      secondaryMessage: string;
      action: string;
    }
  ) {}

  async ngOnInit(): Promise<void> {
    this.showSpinner = true;
    const response = await lastValueFrom(this.subscriptionService.getPrices());
    this.yearlyPrice = response.yearly;
    this.monthlyPrice = response.monthly;
    this.discount = response.discount;
    this.monthlyUrl = response.monthlyUrl;
    this.yearlyUrl = response.yearlyUrl;
    this.showSpinner = false;
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedPlan = tabChangeEvent.tab.textLabel.toLowerCase();
  }

  subscribe(): void {
    this.dialogRef.close({
      monthlyUrl: this.monthlyUrl,
      yearlyUrl: this.yearlyUrl,
      selectedPlan: this.selectedPlan,
    });
  }
}
