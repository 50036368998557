import { Component, Input } from '@angular/core';
import { path } from '../../interfaces/breadcrumb/breadcrumb.interface';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() path!: path[];
  @Input() loading: boolean = false;
}
