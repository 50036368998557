<h1 mat-dialog-title>Subscribe <mat-icon mat-dialog-close>close</mat-icon></h1>
<div class="content" mat-dialog-content>
  <p>
    You need to choose one of our plans to keep using the platform (you will be logged off if you
    leave this window without subscribing)
  </p>
  <br />

  <div *ngIf="showSpinner" class="spinner-content">
    <app-spinner></app-spinner>
  </div>

  <div *ngIf="!showSpinner" class="subscription-plan-toggle-container">
    <mat-tab-group animationDuration="0ms" dynamicHeight (selectedTabChange)="tabChanged($event)">
      <mat-tab label="Annually">
        <div class="annually-discount">
          <span class="total-price">{{ "$" + monthlyPrice?.toFixed(2) }}</span>
          <span class="percent-discount">{{ discount }}% off annually</span>
        </div>
        <span class="period-plan-price">{{
          "$" + ((yearlyPrice ? yearlyPrice : 0) / 12).toFixed(2)
        }}</span>
        <span class="period-plan-text">/ per month</span>
      </mat-tab>
      <mat-tab label="Monthly">
        <span class="period-plan-price">{{ "$" + monthlyPrice?.toFixed(2) }}</span>
        <span class="period-plan-text">/ per month</span>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<div class="actions" mat-dialog-actions>
  <kwfSecondaryButton [text]="'cancel'" [showArrow]="false" mat-dialog-close></kwfSecondaryButton>
  <kwfBlueButton
    [text]="'SUBSCRIBE'"
    (action)="subscribe()"
    [disabled]="showSpinner"
    [ngStyle]="{ opacity: showSpinner ? 0.4 : 1 }"
  ></kwfBlueButton>
</div>
