<h1 mat-dialog-title>{{ data.title }} <mat-icon mat-dialog-close>close</mat-icon></h1>
<div class="content" mat-dialog-content>
  <p>{{ data.primaryMessage }}</p>
  <br />
  <p>{{ data.secondaryMessage }}</p>
</div>

<div class="new-keyword-list">
  <mat-form-field appearance="standard" hideRequiredMarker="true">
    <mat-label>Keyword</mat-label>
    <input [formControl]="keywordList" matInput />
    <mat-hint *ngIf="keywordList.touched && keywordList.invalid">
      <span>!</span>
      Keywords is required
    </mat-hint>
  </mat-form-field>
</div>

<div class="actions" mat-dialog-actions>
  <kwfSecondaryButton [text]="'cancel'" [showArrow]="false" mat-dialog-close></kwfSecondaryButton>
  <kwfBlueButton [text]="data.action" (action)="close()"></kwfBlueButton>
</div>
