<div class="privacy-section">
  <app-back-homepage-header></app-back-homepage-header>

  <div class="privacy-container">
    <div class="privacy-title-container">
      <h1>User guidelines</h1>
    </div>

    <small>Date of last update: April 1, 2023</small>

    <div class="privacy-description-container">
      <br />
      <p>
        This is Apex Compliance’s User Guidelines ("User Guidelines") which apply when using Apex
        Compliance's websites, applications and services that reference these User Guidelines (the
        "Software Services") including accessing any type of material or content made available
        through those Software Services ("Content").
      </p>
      <br />

      <p>
        We may update these User Guidelines from time to time - you can find the latest version on
        our website.
      </p>
      <br />

      <p>
        Violating the User Guidelines may result in the termination or suspension of your account.
        We try to make the Software Services broadly available to everyone, but you cannot use our
        Software Services if we've previously terminated your account on any of our Software
        Services. We also prohibit attempts to circumvent prior enforcement actions, including
        through the creation of new accounts.
      </p>
      <br />

      <p>
        The following is not permitted for any reason whatsoever in relation to the Software
        Services and the material or content made available through the Software Services, or any
        part thereof:
      </p>
      <br />

      <ol>
        <li>
          reverse-engineering, decompiling, disassembling, modifying, or creating derivative works,
          except where such restriction is expressly prohibited by applicable law. If applicable law
          allows you to decompile any part of the Software Services or Content where required in
          order to obtain the information necessary to create an independent program that can be
          operated with the Software Services or with another program, the information you obtain
          from such activities (a) may only be used for the foregoing objective, (b) may not be
          disclosed or communicated without Apex Compliance's prior written consent to any third
          party to whom it is not necessary to disclose or communicate in order to achieve that
          objective, and (c) may not be used to create any software or service that is substantially
          similar in its expression to any part of the Software Services or the Content;
        </li>
        <br />

        <li>
          copying, reproducing, redistributing, "ripping," recording, transferring, performing,
          framing, linking to or displaying to the public, broadcasting, or making available to the
          public, or any other use which is not expressly permitted under the Agreements or
          applicable law, or which otherwise infringes intellectual property rights;
        </li>
        <br />

        <li>
          importing or copying any local files that you do not have the legal right to import or
          copy in this way;
        </li>
        <br />

        <li>
          transferring copies of cached Content from an authorized device to any other device via
          any means;
        </li>
        <br />

        <li>
          "crawling" or "scraping", whether manually or by automated means, or otherwise using any
          automated means (including bots, scrapers, and spiders), to view, access or collect
          information;
        </li>
        <br />

        <li>
          selling, renting, sublicensing, leasing or other monetization except as expressly
          permitted under the applicable Software Service;
        </li>
        <br />

        <li>
          circumventing any technology used by Apex Compliance, its licensors, or any third party,
          including any territorial or other content access restrictions applied by Apex Compliance
          or its licensors;
        </li>
        <br />

        <li>
          removing or altering any copyright, trademark, or other intellectual property notices
          (including for the purpose of disguising or changing any indications of ownership or
          source);
        </li>
        <br />

        <li>
          deleting or altering any part of the Software Services or Content except as expressly
          permitted under the Software Services; or
        </li>
        <br />

        <li>
          providing your password to any other person or using any other person's username and
          password.
        </li>
      </ol>
    </div>
  </div>
</div>
