<div class="edit-delete-report-container">
  <div class="reports-links edit-content" (click)="editReport()">
    <mat-icon svgIcon="edit"></mat-icon>
    <p>Rename report</p>
  </div>

  <div class="reports-links delete-content" (click)="deleteReport()">
    <mat-icon svgIcon="trash"></mat-icon>
    <p>Delete report</p>
  </div>
</div>
