import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kwfButton',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss', '../../btn.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() text!: string;
  @Input() disabled = false;
  @Input() uppercase = false;
  @Input() startSelected = false;
  @Input() isToggle = false;
  @Output() action = new EventEmitter();

  selected: boolean = false;

  ngOnInit(): void {
    this.selected = this.startSelected;
  }

  click() {
    if (this.isToggle) {
      this.selected = !this.selected;
    }
    this.action.emit();
  }
}
